<template>
    <footer class="footer">
        <b-container fluid>
            <b-row>
                <b-col col sm="6">
                    {{ new Date().getFullYear() }} © Velzon.
                </b-col>
                <b-col col sm="6">
                    <div class="text-sm-end d-none d-sm-block">
                        Design & Develop by Themesbrand
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>
